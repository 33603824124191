import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import no_avatar from '../assets/no_avatar.png';
import cross from '../assets/cross.svg';
import editpen from './edit.svg'
import axios from 'axios';
import './style.css';


const UserProfile = () => {

  const { id } = useParams(); // Получаем параметр id из URL
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [banInfo, setBanInfo] = useState(null);
  const [isButtonBlocked, setIsButtonBlocked] = useState(false);
  const [solarStaffVerified, setSolarStaffVerified] = useState(null);
  const [multiacc, setMultiacc] = useState(null);
  const [solarStaffReg, setSolarStaffReg] = useState(null);

  const [showFinesModal, setShowFinesModal] = useState(false);
const [withdrawAmount, setWithdrawAmount] = useState(0);
const [loading, setLoading] = useState(false);

const [callHistory, setCallHistory] = useState([]);
const [isCallHistoryVisible, setIsCallHistoryVisible] = useState(false);

const [balanceFromCalls, setBalanceFromCalls] = useState(0);
const [balanceFromStream, setBalanceFromStream] = useState(0);
const [balanceOutsideStream, setBalanceOutsideStream] = useState({
  tasks_balance: 0,
  calls: 0,
  gifts: 0,
  chat: 0
});
const [balanceInsideStream, setBalanceInsideStream] = useState({
  stream_balance: 0,
  gifts: 0
});

const fetchUserDetails = async () => {
  try {
    const response = await fetch('https://golive.mobi/api/user/user-detail/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error('Error fetching user details');
    }

    const data = await response.json();
    setBalanceHistory(data.balance_history);
  } catch (error) {
    console.error('Error fetching user details:', error);
  }
};

useEffect(() => {
  if (id.length < 21) {
    fetchUserDetails();
  }
}, [id]);

const fetchCallHistory = async () => {
  try {
    const response = await axios.post('https://golive.mobi/api/call/history/', {
      id: id,
      page_size: 50
    }, {
      headers: {
        'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      setCallHistory(response.data.list);
    } else {
      throw new Error('Error fetching call history');
    }
  } catch (error) {
    console.error('Error fetching call history:', error);
  }
};

const toggleCallHistory = () => {
  if (!isCallHistoryVisible && callHistory.length === 0) {
    fetchCallHistory();
  }
  setIsCallHistoryVisible(!isCallHistoryVisible);
};

useEffect(() => {
  fetchCallHistory(); // Загружаем историю звонков вместе с профилем
}, [id]);

const fetchUserStatusDebug = async () => {
  try {
    const response = await fetch('https://golive.mobi/api/user/status/debug/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }), // Убедитесь, что 'id' передается правильно
    });

    if (!response.ok) {
      throw new Error('Error fetching user status debug');
    }

    const data = await response.json();

    // Расчет сумм балансов
    const totalBalanceOutsideStream = Object.values(data.balance_outside_stream).reduce(
      (sum, value) => sum + (value || 0), 0
    );
    const totalBalanceInsideStream = Object.values(data.balance_inside_stream).reduce(
      (sum, value) => sum + (value || 0), 0
    );

    // Установка балансов в state
    setBalanceFromCalls(totalBalanceOutsideStream);
    setBalanceFromStream(totalBalanceInsideStream);

    // Установка балансов по категориям
    setBalanceOutsideStream(data.balance_outside_stream);
    setBalanceInsideStream(data.balance_inside_stream);
    setBanInfo(data.debug.ban_info);
  } catch (error) {
    console.error('Error fetching user status debug:', error);
  }
};

// Функция для форматирования строки баланса с проверкой на null
const formatBalance = (label, value) => {
  return `${label}: ${value !== null ? value : 0}`;
};


const handleBlockButtonClick = () => {
  if (!isButtonBlocked) {
    setIsButtonBlocked(true);

    const apiUrl = 'https://golive.mobi/api/user/m-delete/';
    const requestData = [
      {
        "id": id,
        "signed_up": 1
      }
    ];

    axios.post(apiUrl, requestData, {
      headers: {
        'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200) {
          window.location.reload(); // Перезагрузка страницы при успешном ответе
        }
      })
      .catch(error => {
        console.error('Error blocking user:', error);
      })
      .finally(() => {
        setIsButtonBlocked(false);
      });
  }
};

  

  const handleButtonClick = () => {
    if (!isButtonBlocked) {
      setIsButtonBlocked(true);

      // Ваш URL и данные для отправки
      const apiUrl = 'https://golive.mobi/api/user/m-delete/';
      const requestData = [
        {
          "id": id,
          "signed_up": 1
        }
      ];

      // Отправляем запрос
      axios.post(apiUrl, requestData, {
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // Обработка успешного ответа, если необходимо
          console.log(response.data);
        })
        .catch(error => {
          // Обработка ошибок, если необходимо
          console.error(error);
        })
        .finally(() => {
          // После завершения запроса разблокируем кнопку
          setIsButtonBlocked(false);
        });
    }
  };

  const [userProfile, setUserProfile] = useState(null);
  const [showPublicPhotos, setShowPublicPhotos] = useState("public");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showStories, setShowStories] = useState(false); // New state to handle showing/hiding stories
  const [showAddMedia, setShowAddMedia] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [test, setTest] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [nameedit, setNameedit] = useState(''); // Заменяем editedName на name
  const [ageedit, setAgeedit] = useState(''); // Заменяем editedAge на age
  const [balance, setBalance] = useState(0);

  const handleUnblockButtonClick = () => {
    const apiUrlUnblock = 'https://golive.mobi/api/user/m-undelete/';
    const apiUrlEnableStream = 'https://golive.mobi/api/stream/enable/';
    const requestDataUnblock = [
      {
        "id": id,
        "signed_up": 1
      }
    ];
    const requestDataEnableStream = {
      "id": id,
      "value": 1
    };
  
    // Отправляем запрос для разблокировки пользователя
    axios.post(apiUrlUnblock, requestDataUnblock, {
      headers: {
        'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200) {
          // Отправляем запрос для включения стрима
          axios.post(apiUrlEnableStream, requestDataEnableStream, {
            headers: {
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json'
            }
          })
            .then(response => {
              if (response.status === 200) {
                // Перезагружаем страницу при успешном ответе
                window.location.reload();
              }
            })
            .catch(error => {
              console.error('Error enabling stream:', error);
            });
        }
      })
      .catch(error => {
        console.error('Error unblocking user:', error);
      });
  };
  

  const fetchUserBalance = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/coin/status/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
  
      if (!response.ok) {
        throw new Error('Error fetching user balance');
      }
  
      const data = await response.json();
      setBalance(data.balance);
    } catch (error) {
      console.error('Error fetching user balance:', error);
    }
  };
  

  const handleSaveProfile = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/update/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          age: ageedit,
          name: nameedit,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating profile.');
      }
  
      // Обработка успешного обновления профиля (например, обновление состояния)
      setNameedit(nameedit);
setAgeedit(ageedit);
  
      // Закрыть редактирование профиля
      setIsEditingProfile(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  


  const toggleShowAddMedia = () => {
    setShowAddMedia(!showAddMedia);
  };
  const nottoggleShowAddMedia = () => {
    setShowAddMedia(false);
  };




  const [selectedMediaType, setSelectedMediaType] = useState(""); // New state to store the selected media type



  const handleFileUploadVideo = async () => {
    const fileInput = document.getElementById("fileInput");
  
    if (fileInput.files.length === 0) {
      alert("Please select a file to upload.");
      return;
    }
  
    const file = fileInput.files[0];
  
    try {
      console.log("File selected:", file);
  
      setFileUploaded(true);
  
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);
  
      videoElement.onloadedmetadata = async () => {
        console.log("Video metadata loaded");
  
        // Set the video duration when metadata is loaded
        let videoDuration = videoElement.duration;
        console.log("Video duration from metadata:", videoDuration);
  
        // Send the video to the backend to get its duration
        const formData = new FormData();
        //formData.append("video_file", file);
  
        try {
          const response = await fetch("https://admin.golive.mobi/howlongvideo", {
            method: "POST",
            body: formData,
          });
  
          if (!response.ok) {
            throw new Error("Error getting video duration.");
          }
  
          const data = await response.json();
          const durationSeconds = data.duration;
  
          // Update videoDuration with the duration from the backend
          videoDuration = durationSeconds;
          console.log("Video duration from backend:", videoDuration);
        } catch (error) {
          console.error("Error getting video duration:", error);
        }
  
        // Set the video duration as received from the backend
        setVideoDuration(videoDuration);
  
        // Continue with the rest of your code to upload the file...
        let url = `https://golive.mobi/api/user/image/upload/?id=${id}&type=${selectedMediaType}`;
  
        //const formData = new FormData();
  
        const fileExtension = file.name.split('.').pop().toLowerCase();
  
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
          url += "&file_type=image";
          formData.append("image", file);
        } else if (['mp4', 'avi', 'mov', 'mkv', 'webm'].includes(fileExtension)) {
          url += "&file_type=video&duration=" + Math.floor(videoDuration);
          formData.append("image", file);
        } else {
          alert("Unsupported file type. Please select an image or a video file.");
          return;
        }
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
          },
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error("Error uploading media.");
        }
  
        // Обработка успешной загрузки здесь, если необходимо
      };
      
      //videoElement.load(); // Load the video to extract metadata
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  
    setShowAddMedia(false);
    setFileUploaded(false);
  };
  
  
  const [copied, setCopied] = useState(false);

const handleCopyClick = () => {
  navigator.clipboard.writeText(id);
  setCopied(true);
  setTimeout(() => setCopied(false), 2000);
};
  
  const handleFileUploadPhoto = async () => {
    const fileInput = document.getElementById("fileInput");
  
    if (fileInput.files.length === 0) {
      alert("Please select a file to upload.");
      return;
    }
  
    const file = fileInput.files[0];
  
    try {
      console.log("File selected:", file);
  
      setFileUploaded(true);
      const formData = new FormData();
      let url = `https://golive.mobi/api/user/image/upload/?id=${id}&type=${selectedMediaType}&file_type=image`;
  
      
      formData.append("image", file); // Append the file to the FormData
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Error uploading media. Status: ${response.status} - ${response.statusText}`);
      }
  
      // Handle successful upload here if needed
  
      //videoElement.load(); // Load the video to extract metadata
    } catch (error) {
      console.error("Error uploading media:", error.message); // Log the error message
    }
  
    setShowAddMedia(false);
    setFileUploaded(false);
  };
  



useEffect(() => {
  const fetchUserProfile = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/public-view2/', {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          user2_id: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Error fetching user profile');
      }

      

      const data = await response.json();
      setUserProfile(data);

      fetchUserBalance();
      fetchUserStatusDebug();

      if (userProfile && userProfile.name && userProfile.age) {
        document.title = `${userProfile.name}, ${userProfile.age}`;
      }



      console.log = function () {};
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  // Используйте setInterval, чтобы запускать fetchUserProfile каждые две секунды
  const fetchInterval = setInterval(() => {
    fetchUserProfile();
  }, 2000);

  // Очищайте интервал при размонтировании компонента или при изменении зависимостей
  return () => {
    clearInterval(fetchInterval);
  };
}, [id]); // Здесь id, или другие зависимости, которые могут изменяться


  const fetchUserProfileOne = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/public-view2/', {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          user2_id: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Error fetching user profile');
      }

      

      const data = await response.json();
      setUserProfile(data);
      setNameedit(data.name);
      setAgeedit(data.age);

      if (userProfile && userProfile.name && userProfile.age) {
        document.title = `${userProfile.name}, ${userProfile.age}`;
      }



      console.log = function () {};
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };




const handleEditProfile = () => {
  fetchUserProfileOne();
  setIsEditingProfile(true);
};

const handleCancelEditProfile = () => {
  setIsEditingProfile(false);
};

const handleSaveProfiles = async () => {
  // Обработка сохранения профиля
  setIsEditingProfile(false);
};

  const togglePhotosView1 = () => {
    setShowPublicPhotos("public");
    setShowStories(false); // Hide stories when switching to public photos
  };

  const togglePhotosView2 = () => {
    setShowPublicPhotos("private");
    setShowStories(false); // Hide stories when switching to private photos
  };

  const togglePhotosView3 = () => {
    setShowPublicPhotos("stories");
    setShowStories(false); // Hide stories when switching to private photos
  };

  const toggleShowStories = () => {
    setShowStories(!showStories); // Toggle the state for showing/hiding stories
    setSelectedMedia(null); // Close the selected media popup if it's open
  };

  const handleMediaClick = (mediaURL) => {
    window.open(mediaURL, '_blank');
  };

  const handleMediaClick1 = (mediaURL, index) => {
    const shouldDelete = window.confirm('Do you want to delete this media?');
    if (shouldDelete) {
      // Send the POST request to delete the media here
      deleteMedia(mediaURL, index);
    }
  };

  const deleteMedia = async (mediaURL, index) => {
    try {
      const response = await fetch('https://golive.mobi/api/user/image/delete/url/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file: mediaURL,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error deleting media.');
      }
  
      // Handle successful deletion here, if needed
      // For example, you can remove the media from the state
      handleRemoveMedia(index);
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const handleMediaClick2 = (mediaURL, index) => {
    const shouldDelete = window.confirm('Do you want to delete this media?');
    if (shouldDelete) {
      // Send the POST request to delete the media here
      deleteCover(mediaURL, index);
    }
  };

  const deleteCover = async (mediaURL, index) => {
    try {
      const response = await fetch('https://golive.mobi/api/user/image/delete/url/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file: cover,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error deleting media.');
      }
  
      // Handle successful deletion here, if needed
      // For example, you can remove the media from the state
      handleRemoveMedia(index);
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const handleMediaClick3 = (mediaURL, index) => {
    const shouldDelete = window.confirm('Do you want to delete this media?');
    if (shouldDelete) {
      // Send the POST request to delete the media here
      deleteAvatar(mediaURL, index);
    }
  };

  const deleteAvatar = async (mediaURL, index) => {
    try {
      const response = await fetch('https://golive.mobi/api/user/image/delete/url/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file: avatar,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error deleting media.');
      }
  
      // Handle successful deletion here, if needed
      // For example, you can remove the media from the state
      handleRemoveMedia(index);
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };
  

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  const {
    avatar,
    name,
    flag,
    countryCode,
    age,
    timeRegister,
    followers_count,
    following_count,
    public: publicPhotos,
    private: privatePhotos,
    bio,
    status,
    story,
    bot,
    cover
  } = userProfile;

  const formattedTimeRegister = new Date(Number(timeRegister)).toLocaleString();

  const publicPhotoCount = userProfile?.public?.length || 0;
  const privatePhotoCount = userProfile?.private?.length || 0;
  const storiesCount = userProfile?.story?.length || 0;


  const handleRemoveMedia = (mediaIndex) => {
    // Define the logic here to remove the media file from the state or perform any other action you want.
    // For example, if media is stored in an array like 'publicPhotos', you can use the following code:
    const updatedPublicPhotos = [...publicPhotos];
    updatedPublicPhotos.splice(mediaIndex, 1);
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      public: updatedPublicPhotos,
    }));
  };

  const toggleFinesModal = () => {
    setShowFinesModal(!showFinesModal); // Переключить видимость модального окна
  };
  
  const handleWithdraw = async () => {
    setLoading(true); // Активировать индикатор загрузки
    try {
      const amountToWithdraw = parseInt(withdrawAmount, 10); // Преобразование строки в число
      const response = await fetch('https://golive.mobi/api/user/m-billing/withdraw/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          amount: amountToWithdraw,
          type: 'fine'
        }),
      });
  
      if (response.ok) {
        // Перезагрузить страницу при успешном ответе
        window.location.reload();
      } else {
        throw new Error('Failed to withdraw coins');
      }
    } catch (error) {
      console.error('Error withdrawing coins:', error);
    } finally {
      setLoading(false); // Деактивировать индикатор загрузки
    }
  };
  
  



  return (
    
    <div className="profile-container">
      
      <div className="profile-header">

      <div>

      {avatar === "https://golive.mobi" ? 
      <img className="avatar" src={no_avatar} alt={'No_avatar'} />
      : 
      <span>
      <img className="avatar" src={avatar} alt={'avatar'} />
      <button
      style={{ 

        border: '2px solid rgb(109, 51, 255)',
borderRadius: '5px',
width: '10%',
background: 'white',
height: '25px',
marginLeft: '-10%',
position: 'absolute',
marginTop: '11%',

       }}
        className="emoji-button"
        onClick={() => handleMediaClick3()}
      >
         Delete avatar 🗑️
      </button></span>
      }

{cover === "" ?

null


: 
<span>
  <p style={{  marginLeft: '37%', position: 'absolute', marginTop: '-10%', fontWeight: '600'}}>Cover profile:</p>
  <img style={{ marginLeft: '35%', position: 'absolute', width: '250px' }} className="avatar" src={cover} alt={'avatar'} />
  <button
                style={{ 

                  border: '2px solid rgb(109, 51, 255)',
    borderRadius: '5px',
    width: '16%',
    background: 'white',
    height: '35px',
    marginLeft: '35%',
    position: 'absolute',
    marginTop: '20.5%',

                 }}
                  className="emoji-button"
                  onClick={() => handleMediaClick2()}
                >
                   Delete cover 🗑️
                </button>
</span>}

</div>

      

<div style={{ display: 'flex'}}>

{isEditingProfile ? (
            <div style={{ marginTop: '4%', marginBottom: '1%', display: 'flex' }}>
              <input
                type="text"
                value={nameedit}
                onChange={(e) => setNameedit(e.target.value)}
                style={{
                  width: '200px',
                  borderRadius: '5px',
                  border: '1px solid #707070',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              />
              {/*<input
                type="text"
                value={ageedit}
                onChange={(e) => setAgeedit(e.target.value)}
/>*/}
              <button className="remove-button" style={{ width: '65px', marginLeft: '1%', background: '#6d33ff'}} onClick={handleSaveProfile}>OK</button>
              <button className="remove-button" style={{ width: '65px'}} onClick={handleCancelEditProfile}>Cancel</button>
            </div>
          ) : (
            <h1 style={{ marginTop: '4%', marginBottom: '1%' }}>
              {name} <img style={{ width: '25px' }} src={editpen} onClick={handleEditProfile} />
            </h1>
          )}


        {/*<h1 style={{ marginTop: '4%', marginBottom: '1%'}}>{name} <img style={{ width: '25px'}} src={editpen} /> </h1>*/}

        {bot === 1 && ( <div
            className="bot-block"
            style={{
              border: '2px solid #0045ff',
              padding: '5px 10px',
              borderRadius: '15px',
              marginLeft: '10px',
              width: '3.5%',
              height: '5%',
              fontWeight: 400,
              marginTop: "4.5%"
            }}
          >
            Bot 🤖
          </div>)}
          </div>
        <div>
        <img className="flag" src={flag} alt={countryCode} />


        {isEditingProfile ? (

        <span style={{ marginTop: '0.5%', color: '#707070'}}> {countryCode} · Age: <input
                type="text"
                value={ageedit}
                style={{
                  width: '50px',
                  borderRadius: '5px',
                  border: '1px solid #707070',
                  color: '#707070'
                }}
                onChange={(e) => setAgeedit(e.target.value)}
/></span>

        ) :

        <span style={{ marginTop: '0.5%', color: '#707070'}}> {countryCode} · Age: {age}</span>
}
        
        </div>
        <span style={{ marginTop: '0.5%', color: '#707070'}}>Joined: {formattedTimeRegister}</span>
        
        <span style={{ marginTop: '0.5%', color: '#707070'}}>Status: <span style={{color: 'black'}}>{status}</span></span>
       
        <div className="followers"  style={{ marginTop: '0.5%'}}>
          <span><span style={{ color: '#707070' }}>Followers:</span> {followers_count}</span>
          <span style={{ marginLeft: '1%'}}><span style={{ color: '#707070' }}>Following:</span> {following_count}</span>
        </div>

        <div style={{ marginTop: '0.5%', color: '#707070' }}>
  Balance (Coins): {balance}
</div>

<div style={{ marginTop: '0.5%', color: '#707070' }}>
        Balance from calls: {balanceFromCalls}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Tasks', balanceOutsideStream?.tasks_balance)}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Calls', balanceOutsideStream?.calls)}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Gifts', balanceOutsideStream?.gifts)}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Chats', balanceOutsideStream?.chat)}
      </div>

      <div style={{ marginTop: '0.5%', color: '#707070' }}>
        Balance from stream: {balanceFromStream}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Stream', balanceInsideStream?.stream_balance)}
      </div>
      <div style={{ marginLeft: '20px', color: '#707070' }}>
        {formatBalance('Gifts', balanceInsideStream?.gifts)}
      </div>

<div style={{ marginTop: '0.5%', color: '#707070' }}>
      Registration in Mellow: <span style={{ color: 'black'}}>{solarStaffReg ? 'Registered' : 'Not registered'}</span>
    </div>



<div style={{ marginTop: '0.5%', color: '#333' }}>
  Verification in Mellow: <span style={{ color: 'black' }}>{solarStaffVerified ? 'Verified' : 'Not verified'}</span>
</div>

<div style={{ marginTop: '0.5%', color: '#333' }}>
  Multi-Account: <span style={{ color: 'black' }}>{multiacc ? 'Yes' : 'No'}</span>
</div>

<div style={{ marginTop: '0.5%', color: '#333' }}>
  Model ID: {id}
  <button 
    style={{
      marginLeft: '10px',
      padding: '5px 10px',
      border: '1px solid #4CAF50',
      borderRadius: '5px',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer',
      fontSize: '14px'
    }} 
    onClick={handleCopyClick}
  >
    {copied ? 'Copied' : 'Copy ID'}
  </button>
</div>



{banInfo && (
  <>
    <div style={{ marginTop: '1%', color: '#333' }}>
      <h3>Actual ban:</h3>
      {banInfo.deleted === 0 ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          The model is not currently banned 🎉
        </div>
      ) : (
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          border: '1px solid #ddd',
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}>
          <thead style={{ backgroundColor: '#f4f4f4' }}>
            <tr>
              <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Reason</th>
              <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {banInfo.deleted_reason === 'man_detected' ? 'Man detected in AI-verification' :
                 banInfo.deleted_reason === 'random_mute' ? 'Out of frame' :
                 banInfo.deleted_reason === 'random_nudity' ? 'Nudity' :
                 banInfo.deleted_reason === 'rejected_web' ? 'Rejected on verification' : 
                 banInfo.deleted_reason}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{banInfo.deleted_date}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>

    <div style={{ marginTop: '1%', color: '#333' }}>
      <h3>History ban:</h3>
      <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}>
        <thead style={{ backgroundColor: '#f4f4f4' }}>
          <tr>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Reason</th>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {banInfo.deleted_history.length > 0 ? (
            banInfo.deleted_history.map((ban, index) => (
              <tr key={index}>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                  {ban.reason === 'man_detected' ? 'Man detected in AI-verification' :
                   ban.reason === 'random_mute' ? 'Out of frame' :
                   ban.reason === 'random_nudity' ? 'Nudity' :
                   ban.reason === 'rejected_web' ? 'Rejected on verification' : 
                   ban.reason}
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{ban.date}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td style={{ padding: '10px', textAlign: 'center' }} colSpan="2">
                No history ban 🎉
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </>
)}

<div style={{ marginTop: '1%', color: '#333' }}>
  <h3 onClick={() => setIsCallHistoryVisible(!isCallHistoryVisible)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
    History calls (Time in UTC)
    <button style={{
      marginLeft: '10px',
      padding: '10px 40px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#6d33ff',
      color: 'white',
      cursor: 'pointer',
    }}>
      {isCallHistoryVisible ? '▲' : '▼'}
    </button>
  </h3>
  {isCallHistoryVisible && (
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid #ddd',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      marginTop: '10px',
    }}>
      <thead style={{ backgroundColor: '#f4f4f4' }}>
        <tr>
          <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Dialog ID</th>
          <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Duration</th>
          <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Name</th>
          <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Date</th>
          <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Status</th> {/* Новый столбец */}
        </tr>
      </thead>
      <tbody>
        {callHistory.length > 0 ? (
          callHistory.map((call, index) => (
            <tr key={index}>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{call.dialog_id}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{call.duration}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{call.name}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {call.date_str.split('.')[0]}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{call.dialog_status}</td> {/* Новый столбец */}
            </tr>
          ))
        ) : (
          <tr>
            <td style={{ padding: '10px', textAlign: 'center' }} colSpan="5"> {/* Изменено colSpan */}
              No call history available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )}
</div>

<div style={{ marginTop: '1%', color: '#333' }}>
  {balanceHistory.length > 0 && (
    <>
      <h3>Balance History:</h3>
      <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}>
        <thead style={{ backgroundColor: '#f4f4f4' }}>
          <tr>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Type</th>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Amount</th>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Transaction Type</th>
            <th style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {balanceHistory.map((entry, index) => (
            <tr key={index}>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {entry.balance_history_type === 'in' ? 'In' : 'Out'}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{entry.amount}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{entry.type}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{entry.date.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )}
</div>

    
        <div>
        <button  onClick={togglePhotosView1} className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Public Photo (${publicPhotoCount})`}
        </button>
        
        <button  onClick={togglePhotosView2}   className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Private Photo (${privatePhotoCount})`}
        </button>

        <button onClick={togglePhotosView3}  className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Stories (${storiesCount})`}
        </button>
        <button onClick={toggleShowAddMedia} className="remove-button"  style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        Add media
      </button>


      <button
  className="remove-button"
  style={{
    backgroundColor: 'rgb(255 255 255)',
    marginTop: '1%',
    marginLeft: '0%',
    border: '5px solid rgb(255 51 51)',
    color: 'black',
    cursor: isButtonBlocked ? 'not-allowed' : 'pointer', // меняем курсор в зависимости от состояния
  }}
  onClick={handleBlockButtonClick} // Добавляем обработчик клика
>
  {isButtonBlocked ? 'Blocked' : 'Block model'}
</button>


    <button
  className="remove-button"
  style={{
    backgroundColor: 'rgb(255 255 255)',
    marginTop: '1%',
    marginLeft: '10px',
    border: '5px solid rgb(0, 204, 0)', // Зеленая рамка
    color: 'black',
    cursor: 'pointer'
  }}
  onClick={handleUnblockButtonClick}
>
  Unblock model
</button>

    <button
    className="remove-button"
  style={{
    backgroundColor: 'rgb(255 255 255)',
    marginTop: '1%',
    marginLeft: '10px',
    border: '5px solid rgb(255, 208, 10)', // Жёлтая рамка
    color: 'black'
  }}
  onClick={toggleFinesModal} // Функция для открытия модального окна
>
  Fines
</button>

{showFinesModal && (
  <>
    {/* Затемненный фон */}
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999'  // Убедитесь, что z-index меньше, чем у модального окна
    }} onClick={() => setShowFinesModal(false)}></div>

    {/* Модальное окно */}
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      background: 'white',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      zIndex: '1000',
      border: '1px solid #ccc'
    }}>
      <h2 style={{ textAlign: 'center', color: '#333' }}>How much to fine the model?</h2>
      <p>Current balance: {balance}</p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <label htmlFor="withdrawAmount" style={{ marginRight: '10px' }}>Withdraw coins:</label>
        <input
          id="withdrawAmount"
          type="number"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          style={{ flex: '1', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <button onClick={handleWithdraw} style={{
            padding: '10px 20px',
            background: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>Withdraw Coins</button>
        )}
        <button onClick={() => setShowFinesModal(false)} style={{
          padding: '10px 20px',
          background: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}>Cancel</button>
      </div>
    </div>
  </>
)}




      {showAddMedia && (
             <>
             <div className="white-overlay"></div>
             <div className="media-upload-block">
               <div style={{ display: "flex", alignItems: "center" }}>
                 <h2 style={{ marginTop: "3%", marginLeft: "3%" }}>Upload media</h2>
              
                 <img
                   src={cross}
                   style={{ width: "3%", marginLeft: "auto", marginTop: "2%", marginRight: "3%" }}
                   onClick={nottoggleShowAddMedia}
                 />
               </div>

               <p style={{ marginLeft: "2%" }}>Choose file type:</p>
        <select
          style={{ marginTop: "1%" }}
          className="billing_search_field"
          
          onChange={(e) => setTest(e.target.value)}
        >
          <option value="">Select media type</option>
          <option value="image">Image</option>
          <option value="video">Video</option>
        </select>
               
               {test === "image" && (
        <>
          
          <p style={{ marginLeft: "2%" }}>Choose file:</p>
               <input style={{ marginLeft: "2%" }} type="file" id="fileInput" />
               <br />
               <br />
               
                 <>
                   <p style={{ marginLeft: "2%" }}>Which section to upload the file to:</p>
                   <select
                     style={{ marginTop: "1%" }}
                     className="billing_search_field"
                     value={selectedMediaType}
                     onChange={(e) => setSelectedMediaType(e.target.value)}
                     
                   >
                     <option value="">Select media type</option>
                     <option value="cover">Cover</option>
                     <option value="avatar">Avatar</option>
                     <option value="public">Public</option>
                     <option value="private">Private</option>
                   </select>
                 </>

        </>
      )}
      {test === "video" && (

        
        <>
          
          <p style={{ marginLeft: "2%" }}>Choose file:</p>
               <input style={{ marginLeft: "2%" }} type="file" id="fileInput" />
               <br />
               <br />
               
                 <>
                   <p style={{ marginLeft: "2%" }}>Which section to upload the file to:</p>
                   <select
                     style={{ marginTop: "1%" }}
                     className="billing_search_field"
                     value={selectedMediaType}
                     onChange={(e) => setSelectedMediaType(e.target.value)}
                     
                   >
                     <option value="">Select media type</option>
                     <option value="public">Public</option>
                     <option value="private">Private</option>
                     <option value="story">Story</option>
                   </select>
                 </>
          
        </>
      )}

               
              
     
              
                 <>
                   <br />
                   <br />
                   <br />

                   {test === "image" && (
                   <button
                     className="remove-button"
                     style={{
                       backgroundColor: "rgb(255 255 255)",
                       marginTop: "1%",
                       marginLeft: "2%",
                       border: "2px solid rgb(109, 51, 255)",
                       color: "black",
                     }}
                     onClick={handleFileUploadPhoto}
                     
                   >
                     Upload photo
                   </button>)}
                   {test === "video" && (
                   <button
                     className="remove-button"
                     style={{
                       backgroundColor: "rgb(255 255 255)",
                       marginTop: "1%",
                       marginLeft: "2%",
                       border: "2px solid rgb(109, 51, 255)",
                       color: "black",
                     }}
                     onClick={handleFileUploadVideo}
                     
                   >
                     Upload video
                   </button>)}

                   {fileUploaded && <p style={{ marginLeft: "2%" }}>Loading, please wait...</p>}

                 </>
              
             </div>
           </>
     
      )}


        </div>
      </div>




   


<div className="photo-gallery">
        {showPublicPhotos === "public" ? (
          <div className="public-photos">
            <h2>Public Photos</h2>
            <div className="photo-grid">
              {publicPhotos.map((media, index) => {
                if (media.file_type === 'image') {
                  return (
                    <div key={index} className="media-item">
                <img style={{  }}
                  className="photo-item"
                  src={media.file}
                  alt={`Public Photo ${index}`}
                  onClick={() => handleMediaClick(media.file, index)}
                />
                <button
                style={{ border: '2px solid rgb(109, 51, 255)',
                borderRadius: '5px', width: '100%', background: 'white' }}
                  className="emoji-button"
                  onClick={() => handleMediaClick1(media.file, index)}
                >
                   Delete media 🗑️
                </button>
              </div>
                  );
                } else if (media.file_type === 'video') {
                  return (
                    <div key={index} className="media-item">
                <video  style={{  }}
                  className="photo-item"
                  src={media.file}
                  alt={`Public Video ${index}`}
                  onClick={() => handleMediaClick(media.file, index)}
                />
                <button
                style={{ border: '2px solid rgb(109, 51, 255)',
                  borderRadius: '5px', width: '100%', background: 'white' }}
                  className="emoji-button"
                  onClick={() => handleMediaClick1(media.file, index)}
                >
                   Delete media 🗑️
                </button>
              </div>
                    
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        ) : (
          null
        )}
      </div>


      <div className="photo-gallery">
        {showPublicPhotos === "private" ? (
          <div className="public-photos">
            <h2>Private Photos</h2>
            <div className="photo-grid">
              {privatePhotos.map((media, index) => {
                if (media.file_type === 'image') {
                  return (
                    <div key={index} className="media-item">
                    <img style={{  }}
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Private Photo ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    /><button
                    style={{ border: '2px solid rgb(109, 51, 255)',
                      borderRadius: '5px', width: '100%', background: 'white' }}
                      className="emoji-button"
                      onClick={() => handleMediaClick1(media.file, index)}
                    >
                       Delete media 🗑️
                    </button></div>
                  );
                } else if (media.file_type === 'video') {
                  return (<div key={index} className="media-item">
                    <video  style={{  }}
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Private Video ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    /><button
                    style={{ border: '2px solid rgb(109, 51, 255)',
                      borderRadius: '5px', width: '100%', background: 'white' }}
                      className="emoji-button"
                      onClick={() => handleMediaClick1(media.file, index)}
                    >
                       Delete media 🗑️
                    </button></div>
                    
                  );
                } else {
                  return null;
                }
              })}
              
            </div>
          </div>
        ) : (
          null
        )}
      </div>

      <div className="photo-gallery">
      {showPublicPhotos === "stories" ? (
      <div className="public-photos">
        <h2>Stories</h2>
        <div className="photo-grid">
          {story.map((media, index) => {
            if (media.file_type === 'image') {
              return (
                <div key={index} className="media-item">
                <img style={{  }}
                  key={index}
                  className="photo-item"
                  src={media.file}
                  alt={`Stories ${index}`}
                  onClick={() => handleMediaClick(media.file)}
                /><button
                style={{ border: '2px solid rgb(109, 51, 255)',
                  borderRadius: '5px', width: '100%', background: 'white' }}
                  className="emoji-button"
                  onClick={() => handleMediaClick1(media.file, index)}
                >
                   Delete media 🗑️
                </button></div>
              );
            } else if (media.file_type === 'video') {
              return (<div key={index} className="media-item">
                <video  style={{  }}
                  key={index}
                  className="photo-item"
                  src={media.file}
                  alt={`Stories ${index}`}
                  onClick={() => handleMediaClick(media.file)}
                /><button
                style={{ border: '2px solid rgb(109, 51, 255)',
                  borderRadius: '5px', width: '100%', background: 'white' }}
                  className="emoji-button"
                  onClick={() => handleMediaClick1(media.file, index)}
                >
                  Delete media 🗑️
                </button></div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    ) : null}
      </div>





      {selectedMedia && (
        <div className="popup">
          {selectedMedia.includes('.mp4') ? (
            <video controls>
              <source src={selectedMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={selectedMedia} alt="Selected Media" />
          )}
          <button onClick={() => setSelectedMedia(null)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
